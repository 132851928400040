import { createSlice } from "@reduxjs/toolkit";

export const frustrometroSlice = createSlice({
  name: "frustrometro",
  initialState: {
    phase: "neutral",
  },
  reducers: {
    changePhase: (state, action) => {
      state.phase = action.payload;
    },
    resetPhase: (state) => {
      state.phase = "neutral";
    },
  },
});

export const { changePhase, resetPhase } = frustrometroSlice.actions;

export default frustrometroSlice.reducer;
