import TreeNode from "../../../structures/TreeNode";
import random from "../../../utils/random";

const node1 = new TreeNode(
  0,
  false,
  -1400,
  690,
  "Me gustaría saber más sobre mi retiro y pensión, ya que esa etapa se acerca y no tengo mucha idea de cómo funciona.",
  0,
  "neutral"
);

node1.quotes = [
  "“No había pensado sobre mi pensión, me daba la sensación de que eso lo tenía que ver ya cercano a mi retiro, además nadie te dice nada del tema”",
];

const node1_1 = new TreeNode(
  0,
  false,
  -1400,
  690,
  "Me dijeron que buscara en internet en la página de mi Afore y viera si cuentan con una calculadora de pensión.",
  0
);

const node1_2 = new TreeNode(
  0,
  false,
  -1400,
  690,
  "Estoy con Profuturo, veré si en su página web encuentro más información.",
  0
);

const node2 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Profuturo tiene una app que te da una estimación de tu pensión y en su página tienen una calculadora.",
  random([1, 2]),
  "frustrated"
);

const node2_1 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Ya me registré en la app y vi mi pensión... ¡es casi nada! Me dan la opción de hacer aportaciones, pero no sé bien cómo funcionan ni para qué sirven.",
  0,
  "neutral"
);

node2_1.quotes = [
  "“Te deberían de avisar antes y asesorarte para mejorar tu pensión”",
  "“Al ver de cuánto sería mi me sorprendi del monto, con eso no voy a poder vivir”",
  "“Me gusta que en la app de Profuturo ya me digan de cuánto será mi pensión”",
];

const node2_1_1 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Ya investigué y vi que hay fondos para la pensión y otros que se pueden retirar. Con esto me siento más tranquilo y haré las aportaciones desde la app.",
  random([1, 2])
);

node2_1_1.quotes = [
  "“Inicié a hacer ahorro voluntario desde que me cambié a Profuturo, el asesor me explicó su importancia y se lo agradezco mucho”",
];

const node2_2 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Ya me registré en la app y vi mi pensión... ¡es casi nada! Me dan la opción de hacer aportaciones, pero no sé bien cómo funcionan ni para qué sirven.",
  random([1, 2])
);

const node2_2_1 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Ya vi que puedo hacerlas desde la app Profuturo Móvil, pero como es mi primera vez, no sé si debería pedir asesoría porque hay varios fondos y no sé la diferencia.",
  random([1, 2])
);

const node2_2_1_1 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Después de buscar un rato el teléfono del Call Center, al fin marcaré.",
  0
);

const node2_2_1_1_1 = new TreeNode(2, false, -2250, 1160, "Llamando.", 0);

const node2_2_1_1_2 = new TreeNode(
  1,
  false,
  -2250,
  1160,
  "Hola, buen día. Está marcando a Profuturo, ¿Cómo le podemos ayudar?",
  0
);

const node2_2_1_1_3 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Gracias, quisiera saber cómo hacer aportaciones voluntarias a mi cuenta.",
  0
);

const node2_2_1_1_4 = new TreeNode(
  1,
  false,
  -2250,
  1160,
  "Claro, usted puede elegir cuánto aportar y hacerlo de forma automática cada quincena o mes. Puede revisar toda la información en la app.",
  0
);

const node2_2_1_1_5 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Muy bien, gracias por la información.",
  0
);

const node2_2_1_2 = new TreeNode(
  0,
  false,
  -2250,
  1160,
  "Listo, llamé y me explicaron todo sobre las aportaciones voluntarias y los fondos. Estoy listo para hacerlas en la app Profuturo Móvil.",
  random([1, 2])
);

node2_2_1_2.quotes = [
  "“La idea es que con eso se mejore la pensión, pero ni si quiera sé si veré ese dinero”",
  "“La asistente me recomendó leer el blog de Profuturo, me gustó, es buen contenido y fácil”",
  "“Tuve que marcar para hacer una aportación a mi cuenta, de decirte cómo hacerlo, así no tienes aue marcar”",
];

const node3 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Para empezar, me pide escoger el fondo. Debe ser el de 65 años porque lo quiero para mi pensión",
  0
);

node3.quotes = [
  "“Dentro de la app fue muy fácil hacer la aportación, sólo no supe bien que fondo, pero lo demás estuvo fácil”",
  "“No entiendo bien las diferencias de los diferentes fondos, pero supongo son similares”",
  "“No sabía bien sobre los fondos que ofrecen, marqué y me explicaron muy bien”",
];

const node3_1 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Eligiré el tipo de aportación frecuente, porque lo haré mensualmente.",
  0
);

const node3_2 = new TreeNode(0, true, -3350, 1500, "Juego 1", 0);

node3_2.game = 9;

const node3_2_1 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Quiero que se me cobren 200 pesos cada quincena.",
  0
);

node3_2_1.quotes = [
  "“Quise sólo probar una para ver cómo funciona, y si todo está Ok pues ya haré otras en el futuro”",
];

const node3_2_2 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Quiero que me cobren 500 pesos cada quincena.",
  0
);

node3_2_2.quotes = [
  "“Quise sólo probar una para ver cómo funciona, y si todo está Ok pues ya haré otras en el futuro”",
];

const node3_2_3 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Quiero que se me cobren 500 pesos cada quincena.",
  0
);

node3_2_3.quotes = [
  "“Quise sólo probar una para ver cómo funciona, y si todo está Ok pues ya haré otras en el futuro”",
];

const node3_3 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Ahora tengo que dar de alta mi cuenta bancaria",
  0,
  "frustrated"
);

node3_3.quotes = [
  "“Uno quiere hacer algo por su retiro, y le ponen trabas, me tardé más en registrar mi cuenta de banco que en hacer una aportación”",
];

const node3_4 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Listo, ya quedó. Espero que la próxima quincena me avisen que todo está bien.",
  0,
  "smile"
);

const node4 = new TreeNode(2, false, -3350, 1500, "5 días", 5 * 24);

const node4_1 = new TreeNode(
  0,
  false,
  -3350,
  1500,
  "Ya me notificaron que aceptaron mi cuenta bancaria, pero no recuerdo en qué trámite la usé. Tal vez sea por la aportación voluntaria que hice hace unos días.",
  0,
  "neutral"
);

const node4_1_1 = new TreeNode(2, false, -4400, 2110, "Llamando.", 0);

const node4_1_1_1 = new TreeNode(
  1,
  false,
  -4400,
  2110,
  "Hola, buen día. Está marcando a Profuturo, ¿Cómo le podemos ayudar?",
  0,
  "neutral"
);

const node4_1_1_2 = new TreeNode(
  0,
  false,
  -4400,
  2110,
  "Gracias, quisiera realizar aportaciones voluntarias a mi cuenta",
  0
);

const node4_1_1_3 = new TreeNode(
  1,
  false,
  -4400,
  2110,
  "Para este trámite, necesitamos certificar su cuenta. Una vez hecho, las aportaciones se harán cada quincena.",
  0,
  "frustrated"
);

const node4_1_1_4 = new TreeNode(
  0,
  false,
  -4400,
  2110,
  "Muchas gracias, esperaré.",
  0
);

const node4_2 = new TreeNode(
  0,
  false,
  -4400,
  2110,
  "Llamé y me dijeron que deben certificar mi cuenta para hacer la aportación. Ya la certificaron, ahora solo tengo que esperar.",
  0
);

const node4_3 = new TreeNode(
  2,
  false,
  -4200,
  2710,
  "Pasan unos días (Llega la quincena)",
  24 * random([2, 3]),
  "frustrated"
);

const node4_4 = new TreeNode(
  0,
  false,
  -4200,
  2710,
  "Me notificaron que no se pudo hacer la aportación por falta de fondos. Revisé mi saldo y aún no me cae la quincena. Veré si puedo reprogramarla.",
  0
);

node4_4.quotes = [
  "“Está buenísimo que nos mantengan informados, además cualquier cosa su gente nos atiende”",
];

const node4_6 = new TreeNode(
  0,
  false,
  -4200,
  2710,
  "Listo, espero que hoy me depositen y mañana se haga la aportación sin problema",
  0
);

const node4_7 = new TreeNode(
  0,
  false,
  -4200,
  2710,
  "Ya me llegó la notificación del cobro, pero aún no se refleja en la app. Me dijeron que puede tardar unos días.",
  24
);

node4_7.quotes = [
  "“Nunca me llega la información de mi Afore”",
  "“Deberían de ser más insistentes en tener los datos de sus clientes actualizados, es importante que sepamos de cuánto será nuestra pensión poder hacer algo”",
];

const node5 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  "No puedo entrar a la app. Dice que hay un error de conexión. Intentaré más tarde.",
  0,
  "angry"
);

const node5_1 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  "Ya pude entrar a la app. Qué raro que fallara, me asusté un poco.",
  random([1, 2]),
  "smile"
);

node5_1.quotes = [
  "“Cuando intento retirar o cancelar una aportación por la app, no me deja, es nuestro dinero y no nos dejan sacarlo, ya hasta me preocupé si lo voy a ver algún día”",
  "“Hice el trámite para retirar mi dinero de los fondos de apovol, y todo fluyó muy bien, me asesoraron y ahora estoy feliz con mis ahorros”",
];

const node5_1_1 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  "15 días",
  15 * 24,
  "angry"
);

// node5_1_1.game = 8;

const node5_2 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  `He actualizado mis datos. Me han dicho que estará listo en 5 días. Esperaré la notificación`,
  5 * 24
);

node5_2.quotes = [
  "“En la app, no me decía cuando se iba a hacer mi actualización, entonces estuve esperando y metiéndome todos los días a revisar”",
];

const node5_3 = new TreeNode(
  0,
  true,
  -4200,
  2710,
  "La actualización está lista. Ahora estaré informado sobre todo lo que pasa con mi cuenta de Afore",
  5 * 24,
  "neutral"
);

node5_3.quotes = [
  "“Se tardaron en actualizar mis datos, 5 dias hábiles, se me hace demasiado sólo para cambiar un correo”",
];

const node6 = new TreeNode(2, false, -4200, 2710);

node6.isLast = true;
node6.ending = 0;

node1.descendants = [
  {
    node: node1_1,
    hint: "Le preguntaré a mis amigos si saben algo del tema.",
  },
  {
    node: node1_2,
    hint: "Buscaré más información en internet.",
  },
];

node1_1.descendants = [
  {
    node: node1_2,
    hint: "Continuar",
  },
];

//node1_1.game = 8;

node1_2.descendants = [
  {
    node: node2,
    hint: "Continuar",
  },
];

node2.descendants = [
  {
    node: node2_1,
    hint: "Instalaré la app y ahí revisaré lo de mi pensión",
  },
  {
    node: node2_2,
    hint: "Revisaré que hay en su página",
  },
];

node2_1.descendants = [
  {
    node: node2_1_1,
    hint: "Investigaré primero",
  },
  {
    node: node3,
    hint: "Seguro es para mejorar la pensión, haré la aportación",
  },
];

node2_1_1.descendants = [
  {
    node: node3,
    hint: "Continuar",
  },
];

node2_2.descendants = [
  {
    node: node2_2_1,
    hint: "Continuar",
  },
];

node2_2_1.descendants = [
  {
    node: node3,
    hint: "Instalaré la app y veré cómo hacerlo",
  },
  {
    node: node2_2_1_1,
    hint: "Voy a llamar a Profuturo para que me asesoren",
  },
];

node2_2_1_1.descendants = [
  {
    node: node2_2_1_1_1,
    hint: "Continuar",
  },
];

node2_2_1_1_1.descendants = [
  {
    node: node2_2_1_1_2,
    hint: "Continuar",
  },
];

node2_2_1_1_2.descendants = [
  {
    node: node2_2_1_1_3,
    hint: "Continuar",
  },
];

node2_2_1_1_3.descendants = [
  {
    node: node2_2_1_1_4,
    hint: "Continuar",
  },
];

node2_2_1_1_4.descendants = [
  {
    node: node2_2_1_1_5,
    hint: "Continuar",
  },
];

node2_2_1_1_5.descendants = [
  {
    node: node2_2_1_2,
    hint: "Continuar",
  },
];

node2_2_1_2.descendants = [
  {
    node: node3,
    hint: "Continuar",
  },
];

node3.descendants = [
  {
    node: node3_1,
    hint: "Continuar",
  },
];

node3_1.descendants = [
  {
    node: node3_2,
    hint: "Continuar",
  },
];

node3_2.descendants = [
  {
    node: node3_2_1,
    hint: "$200",
  },
  {
    node: node3_2_2,
    hint: "$500",
  },
  {
    node: node3_2_3,
    hint: "$1000",
  },
];

node3_2_1.descendants = [
  {
    node: node3_3,
    hint: "Continuar",
  },
];

node3_2_2.descendants = [
  {
    node: node3_3,
    hint: "Continuar",
  },
];

node3_2_3.descendants = [
  {
    node: node3_3,
    hint: "Continuar",
  },
];

node3_3.descendants = [
  {
    node: node3_4,
    hint: "Continuar",
  },
];

node3_4.descendants = [
  {
    node: node4,
    hint: "Continuar",
  },
];

node4.descendants = [
  {
    node: node4_1,
    hint: "Continuar",
  },
];

node4_1.descendants = [
  {
    node: node4_1_1,
    hint: "Llamaré a Profuturo para aclarar.",
  },
  {
    node: node4_3,
    hint: "Si es por las aportaciones, esperaré a ver qué pasa.",
  },
];

node4_1_1.descendants = [
  {
    node: node4_1_1_1,
    hint: "Continuar",
  },
];

node4_1_1_1.descendants = [
  {
    node: node4_1_1_2,
    hint: "Continuar",
  },
];

node4_1_1_2.descendants = [
  {
    node: node4_1_1_3,
    hint: "Continuar",
  },
];

node4_1_1_3.descendants = [
  {
    node: node4_1_1_4,
    hint: "Continuar",
  },
];

node4_1_1_4.descendants = [
  {
    node: node4_2,
    hint: "Continuar",
  },
];

node4_2.descendants = [
  {
    node: node4_3,
    hint: "Continuar",
  },
];

node4_3.descendants = [
  {
    node: node4_4,
    hint: "Continuar",
  },
];

node4_4.descendants = [
  {
    node: node4_6,
    hint: "Continuar",
  },
];

node4_6.descendants = [
  {
    node: node4_7,
    hint: "Continuar",
  },
];

node4_7.descendants = [
  {
    node: node5,
    hint: "Continuar",
  },
];

node5.descendants = [
  {
    node: node5_1,
    hint: "Continuar",
  },
];

node5_1.descendants = [
  {
    node: node5_1_1,
    hint: "Continuar",
  },
];

node5_1_1.descendants = [
  {
    node: node5_2,
    hint: "Continuar",
  },
];

node5_2.descendants = [
  {
    node: node5_3,
    hint: "Continuar",
  },
];

node5_3.descendants = [
  {
    node: node6,
    hint: "Continuar",
  },
];

export default node1;
