import { Container, Sprite } from "@inlet/react-pixi";
import { memo, useEffect, useState } from "react";

import Photo from "../../../assets/games/ValidarIdentidad/photo.png";
import PhotoContainer from "../../../assets/games/ValidarIdentidad/rectangle_photo.png";

const items = new Array(28).fill(0);

const PhotoGame = memo(({ reset, startGame, onEndGame = () => null }) => {
  const [itemPosition, setItemPosition] = useState(-250 * items.length);
  const [stop, setStop] = useState(false);

  useEffect(() => {
    if (!startGame) return;
    const interval = setInterval(() => {
      setItemPosition((prev) => prev + 1);
    }, 10);

    if (stop) {
      clearInterval(interval);
      return;
    }

    if (itemPosition > 250) {
      onEndGame(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [itemPosition, startGame, stop, onEndGame]);

  useEffect(() => {
    setItemPosition(-250 * items.length);
    setStop(false);
  }, [reset]);

  return (
    <Container
      position={{
        x: window.innerWidth / 2 - 450,
        y: window.innerHeight / 2 - 150,
      }}
    >
      <Container
        position={{
          x: 350,
          y: window.innerHeight / 4 - 200,
        }}
      >
        <Sprite image={PhotoContainer} interactive height={200} width={150} />
      </Container>
      <Container position={{ x: 0, y: 0 }}>
        {items.map((_, i) => (
          <Container
            interactive
            key={i}
            pointerdown={() => {
              const center = window.innerHeight / 4 - 200;
              const position = i * 300 + itemPosition;
              const isSuccess =
                position > center - 80 && position < center + 80;
              setStop(true);
              onEndGame(isSuccess);
              console.log(isSuccess ? "correcto :D" : "incorrecto");
            }}
            position={[350, i * 300 + itemPosition]}
          >
            <Sprite
              cursor="pointer"
              interactive
              image={Photo}
              height={200}
              width={150}
            />
          </Container>
        ))}
      </Container>
    </Container>
  );
});

export default PhotoGame;
