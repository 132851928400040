import { Container } from "@inlet/react-pixi/animated";
import React, { useEffect, useRef, useState } from "react";
import clock from "../../../assets/anim/data.json";
import LottieSprite from "../../../baseComponents/LottieSprite";
import { useSelector } from "react-redux";
import useWindowSize from "../../../utils/useWindowSize";

const emotions = {
  smile: { start: 0, last: 90 },
  happy: { start: 91, last: 210 },
  neutral: { start: 250, last: 330 },
  frustrated: { start: 330, last: 450 },
  angry: { start: 450, last: 479 },
};

export default function Frustrometro() {
  const { phase } = useSelector((state) => state.frustrometro);
  const windowSize = useWindowSize();
  const [segments, setSegments] = useState([250, 251]);
  const [play, setPlay] = useState(true);
  const [lastEmotion, setLastEmotion] = useState("neutral");
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    if (phase === lastEmotion) return;
    if (segments[1] === emotions[phase].last) return;

    const currentSegments = [segments[1], emotions[phase].last];
    setSegments(currentSegments);
    setPlay(true);
  }, [phase, isInitial, lastEmotion, segments]);

  return (
    <>
      <Container position={[window.innerWidth + 80, window.innerHeight + 60]}>
        <LottieSprite
          key={windowSize.width}
          play={play}
          playSegments={segments}
          data={clock}
          width={window.innerWidth / 7}
          height={window.innerWidth / 7}
          anchor={[1, 1]}
          wait={0}
          animationSpeed={1}
          loop={false}
          onComplete={() => {
            console.log("onComplete");
            setSegments([segments[1], segments[1] + 1]);
            setPlay(false);
            setLastEmotion(phase);
            setIsInitial(false);
          }}
        />
      </Container>
    </>
  );
}
