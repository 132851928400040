import { Container, Text, Graphics } from "@inlet/react-pixi";
import { memo, useCallback, useEffect, useRef, useState } from "react";

import { TextStyle } from "pixi.js";

const items = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const Selector = memo(
  ({ start = false, number, reset, onEndGame = () => null }) => {
    const [itemPosition, setItemPosition] = useState(
      -100 * (items.length - 1) + 40
    );
    const text = useRef();
    const [stop, setStop] = useState(false);
    const maskRef = useRef(null);
    const [textButton, setTextButton] = useState("Parar");

    const style = new TextStyle({
      fontFamily: "Poppins-Medium",
      fontSize: 50,
      align: "center",
      fontWeight: "bold",
      fill: "#127ee0",
      wordWrap: true,
      wordWrapWidth: 350,
    });

    const borderText = useCallback((graphics) => {
      graphics.clear();
      graphics.lineStyle(2, 0xe7993d, 1);
      graphics.beginFill(0x000000);
      graphics.drawRoundedRect(-60, -24, 120, 130, 20);
      graphics.endFill();
    }, []);

    const borderContainer = useCallback((graphics) => {
      graphics.clear();
      graphics.lineStyle(2, 0xe7993d, 1);
      graphics.drawRoundedRect(-60, -24, 120, 130, 20);
      graphics.endFill();
    }, []);

    const label = useCallback((g) => {
      g.clear();
      g.beginFill(textButton === "Listo" ? 0x127ee0 : 0xe8993d, 1);
      g.drawRoundedRect(0, 0, textButton.length * 10 + 50, 35, 40);
      g.endFill();
    });

    const labelBack = useCallback((g) => {
      g.clear();
      g.beginFill(textButton === "Listo" ? 0x127ee0 : 0xd67d17, 1);
      g.drawRoundedRect(0, 0, textButton.length * 10 + 50, 35, 40);
      g.endFill();
    });

    const btnDown = () => {
      console.log("btnDown");
    };

    const btnUp = () => {
      setStop(true);
      setTextButton("Listo");
      // Calculate which number is visible in the mask
      const itemHeight = 100; // height between each number
      const centerPosition = 40; // your center position where numbers should stop
      const currentIndex = Math.round(
        (itemPosition - centerPosition) / itemHeight
      );
      const visibleNumber = items[Math.abs(currentIndex)];
      const targetPosition =
        -100 * (items.length - 1) +
        (visibleNumber * itemHeight + centerPosition);

      // Center the number by setting the exact position
      setItemPosition(targetPosition);

      console.log(
        "Selected number:",
        itemPosition,
        targetPosition,
        visibleNumber
      );
      onEndGame(visibleNumber === Number(number));
    };

    useEffect(() => {
      let interval = setInterval(() => null, 0);
      if (start) {
        clearInterval(interval);
        interval = setInterval(() => {
          setItemPosition((prev) => prev + 2);
        }, 50);

        if (stop) {
          clearInterval(interval);
          return;
        }
        if (itemPosition > 40) {
          btnUp();
          clearInterval(interval);
        }
      }
      return () => clearInterval(interval);
    }, [start, number, itemPosition, stop, onEndGame]);

    useEffect(() => {
      setItemPosition(-100 * (items.length - 1) + 40);
      setStop(false);
    }, [reset]);

    return (
      <Container
        position={{
          x: 0,
          y: 0,
        }}
      >
        <Container
          position={{
            x: 0,
            y: 0,
          }}
        >
          <Graphics ref={maskRef} draw={borderText} zIndex={1} />
          <Graphics draw={borderContainer} zIndex={2} />
        </Container>
        <Container position={{ x: 0, y: itemPosition }} mask={maskRef.current}>
          {items.map((value, i) => (
            <Container interactive key={i} position={[0, i * 100]}>
              <Text
                style={style}
                text={value}
                anchor={0.5}
                x={0}
                y={180}
                position={{ x: 0, y: 0 }}
              />
            </Container>
          ))}
        </Container>

        {textButton === "Listo" ? (
          <Container position={[-50, 130]} interactive="false" cursor="pointer">
            <Graphics draw={(g) => labelBack(g)} />
            <Container x={true ? 2 : -2} y={true ? 2 : -4}>
              <Graphics draw={(g) => label(g)} />
              <Text
                ref={text}
                text={textButton}
                x={25}
                y={6}
                style={
                  new TextStyle({
                    fontFamily: "Poppins-Bold",
                    fontSize: 18,
                    fill: "#fff",
                  })
                }
              />
            </Container>
          </Container>
        ) : (
          <Container
            position={[-50, 130]}
            interactive="true"
            cursor="pointer"
            pointerdown={btnDown}
            pointerup={btnUp}
          >
            <Graphics draw={(g) => labelBack(g)} />
            <Container x={true ? 2 : -2} y={true ? 2 : -4}>
              <Graphics draw={(g) => label(g)} />
              <Text
                ref={text}
                text="Parar"
                x={25}
                y={6}
                style={
                  new TextStyle({
                    fontFamily: "Poppins-Bold",
                    fontSize: 18,
                    fill: "#fff",
                  })
                }
              />
            </Container>
          </Container>
        )}
      </Container>
    );
  }
);

// mask={maskRef.current}

export default Selector;

/*
useEffect(() => {
    let timeout = null;

    if (visible) {
      timeout = setTimeout(close, 1500);
    }

    return () => clearTimeout(timeout);
  }, [visible, close]);
*/
