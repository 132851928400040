import { Graphics, Container, Sprite } from "@inlet/react-pixi";
import { useEffect, useState } from "react";

import photo from "../../../assets/games/docsRetiroAnticipado/photo2.png";
import ine from "../../../assets/games/docsRetiroAnticipado/ine.png";
import tarjeta from "../../../assets/games/docsRetiroAnticipado/tarjeta.png";
import money from "../../../assets/games/docsRetiroAnticipado/money.png";
import check from "../../../assets/games/docsRetiroAnticipado/check.png";

const CELL_SIZE = 40;
const BORDER_RADIUS = 40;

const CONTAINER_HEIGHT = CELL_SIZE * 2;

const convertAlpha = (alpha) => {
  if (alpha === 1) {
    return 0;
  }
  return 1;
};

const InfoBar = ({ cardPositions }) => {
  return (
    <Container
      position={{
        x: window.innerWidth / 6 - 50,
        y: -window.innerHeight / 3 + 50,
      }}
      width={250}
      height={460}
    >
      <Container y={20} width={150} height={CELL_SIZE * 2}>
        <Sprite
          image={check}
          x={50}
          y={25}
          width={CELL_SIZE * 3}
          height={CELL_SIZE * 1.2}
          alpha={
            cardPositions.current.length && cardPositions.current[0]
              ? convertAlpha(cardPositions.current[0].alpha)
              : 0
          }
        />
        <Sprite
          image={photo}
          x={300}
          y={10}
          width={CELL_SIZE * 3}
          height={CELL_SIZE * 2}
        />
      </Container>
      <Container y={CONTAINER_HEIGHT + 20} width={150} height={CELL_SIZE * 2}>
        <Sprite
          image={check}
          x={50}
          y={30}
          width={CELL_SIZE * 3}
          height={CELL_SIZE * 1.2}
          alpha={
            cardPositions.current.length && cardPositions.current[1]
              ? convertAlpha(cardPositions.current[1].alpha)
              : 0
          }
        />
        <Sprite
          image={ine}
          x={280}
          y={25}
          width={CELL_SIZE * 4}
          height={CELL_SIZE * 1.5}
        />
      </Container>
      <Container
        y={CONTAINER_HEIGHT * 2 + 30}
        width={150}
        height={CELL_SIZE * 2}
      >
        <Sprite
          image={check}
          x={50}
          y={25}
          width={CELL_SIZE * 3}
          height={CELL_SIZE * 1.2}
          alpha={
            cardPositions.current.length && cardPositions.current[2]
              ? convertAlpha(cardPositions.current[2].alpha)
              : 0
          }
        />
        <Sprite
          image={money}
          x={280}
          y={20}
          width={CELL_SIZE * 4}
          height={CELL_SIZE * 1.5}
        />
      </Container>
      <Container
        y={CONTAINER_HEIGHT * 3 + 40}
        width={150}
        height={CELL_SIZE * 2}
      >
        <Sprite
          image={check}
          x={50}
          y={25}
          width={CELL_SIZE * 3}
          height={CELL_SIZE * 1.2}
          alpha={
            cardPositions.current.length && cardPositions.current[3]
              ? convertAlpha(cardPositions.current[3].alpha)
              : 0
          }
        />
        <Sprite
          image={tarjeta}
          x={280}
          y={20}
          width={CELL_SIZE * 4}
          height={CELL_SIZE * 1.5}
        />
      </Container>

      <Graphics
        draw={(g) => {
          g.clear();
          g.lineStyle(2, 0xe8993d);
          g.drawRoundedRect(0, 0, 500, 390, BORDER_RADIUS);
        }}
      />
    </Container>
  );
};

export default InfoBar;
