import { Container, Sprite } from "@inlet/react-pixi/animated";
import React, { useEffect, useRef, useState } from "react";
import hand from "../../assets/games/grabGame/hand_large.png";
import GameDialog from "../../baseComponents/GameContainer/components/GameDialog";
import gameOk from "../../assets/games/grabGame/juego3_ganar.png";
import gameWrong from "../../assets/games/grabGame/juego3_perder.png";
import ProgressBar from "../../baseComponents/ProgressBar";
import { addTime } from "../../redux/features/registry/time";
import { useDispatch } from "react-redux";

import Grid from "./components/Grid";
import InfoBar from "./components/InfoBar";

export default function GrabGame({ visible, close, reset }) {
  const dispatch = useDispatch();
  const [mousePosition, setMousePosition] = useState();
  const [message, setMessage] = useState({ status: false, visible: false });
  const [currentTime, setCurrentTime] = useState(0);

  const TIME_LIMIT = 15;
  const cardPositions = useRef([]);
  const grabbedDocs = useRef(0);

  useEffect(() => {
    cardPositions.current = [];
    for (let i = 0; i < 9; i++) {
      cardPositions.current.push({
        id: i,
        scale: 0.7,
        alpha: 1,
      });
    }
  }, []);

  useEffect(() => {
    if (visible) {
      updateTime();
    } else {
      setCurrentTime(0);
    }
  }, [currentTime, visible]);

  const updateTime = () => {
    if (currentTime < TIME_LIMIT) {
      setTimeout(() => {
        setCurrentTime(currentTime + 1);
      }, 1000);
    } else {
      setMessage({ status: false, visible: true });
    }
  };

  const handleMouseMove = (e) => {
    setMousePosition([
      e.data.global.x - window.innerWidth / 2,
      e.data.global.y - window.innerHeight / 2,
    ]);
  };

  const handleClick = (id) => {
    cardPositions.current[id].alpha = 0;
    cardPositions.current[id].scale = 0;
    setMousePosition([...mousePosition]);
    if (id <= 3) {
      grabbedDocs.current++;
      if (grabbedDocs.current === 4) {
        setMessage({ status: true, visible: true });
        close();
      }
    } else {
      dispatch(addTime(24));
      setMessage({ status: false, visible: true });
    }
  };

  const closeDialog = () => {
    if (message.status) {
      close();
    } else {
      grabbedDocs.current = 0;
      setMessage({ status: false, visible: false });
      cardPositions.current.map((e) => {
        e.scale = 0.7;
        e.alpha = 1;
        return e;
      });
      reset();
    }
  };

  if (cardPositions.current.length === 0) return null;

  return (
    <Container>
      <Container
        scale={message.visible ? 0 : 1}
        alpha={message.visible ? 0 : 1}
      >
        <Container
          pivot={[-window.innerWidth / 2, -window.innerHeight / 1.8]}
          mousemove={handleMouseMove}
          interactive
        >
          <Grid cardPositions={cardPositions} onClick={handleClick} />
          <InfoBar cardPositions={cardPositions} />
          <Sprite
            image={hand}
            scale={0.8}
            anchor={[0.5, window.innerHeight / 10 / window.innerHeight]}
            position={mousePosition}
          />
          <ProgressBar
            value={currentTime}
            limit={TIME_LIMIT}
            x={-250}
            y={-530}
          />
        </Container>
      </Container>
      <GameDialog
        image={message.status ? gameOk : gameWrong}
        button={message.status ? "Continuar" : "Seguir buscando"}
        close={closeDialog}
        scale={message.visible ? 1 : 0}
        alpha={message.visible ? 1 : 0}
      />
    </Container>
  );
}
