import { Container, Graphics, Sprite } from "@inlet/react-pixi/animated";
import React, { useRef, useCallback, useEffect, useState, memo } from "react";
import { Spring } from "react-spring";
import img1 from "../../assets/games/shellGame/game_01_intro_v2.png";
import gamesBg from "../../assets/games/games_bg.png";

import GameDialog from "../GameContainer/components/GameDialog";

import message1 from "../../assets/pension/paso14.png";
import message2 from "../../assets/pension/paso15.png";
import message3 from "../../assets/pension/paso17.png";
import message4 from "../../assets/pension/paso18.png";
import message5 from "../../assets/games/docsRetiroAnticipado/paso26.png";
import message6 from "../../assets/games/docsRetiroAnticipado/paso27.png";
import message7 from "../../assets/games/docsRetiroAnticipado/paso28.png";
import { changePhase } from "../../redux/features/fullWithdrawal/frustometro";
import { useDispatch } from "react-redux";

const getMessage = (message) => {
  switch (message) {
    case "ACUDIR_A_SUCURSAL":
      return message1;
    case "ESPERAR_PARA_VOLVER_A_RETIRAR":
      return message2;
    case "IMSS_NO_DISPONIBLE":
      return message3;
    case "CONTINUAR_CON_EL_PROCESO":
      return message4;
    case "VALIDAR_DATOS_ERROR_1":
      return message5;
    case "VALIDAR_DATOS_ERROR_2":
      return message6;
    case "VALIDAR_DATOS_CONTINUAR_PROCESO":
      return message7;
    default:
      return message1;
  }
};

const getButtonLabel = (message) => {
  switch (message) {
    case "ACUDIR_A_SUCURSAL":
      return "Agendar";
    case "VALIDAR_DATOS_ERROR_1":
      return "Salir";
    case "VALIDAR_DATOS_ERROR_2":
      return "Salir";
    case "VALIDAR_DATOS_CONTINUAR_PROCESO":
      return "Elegir monto";
    default:
      return "Continuar";
  }
};

//import { useDispatch } from "react-redux";
//import { addTime } from "../../redux/features/registry/registry";

const Index = memo(
  ({ id = 0, message, currentNode, lastId, close, back = () => null }) => {
    const spring = { mass: 6, tension: 1000, friction: 100 };
    const dispatch = useDispatch();

    useEffect(() => {
      return () => {
        //console.log("UNMONT");
        //setClose(false);
      };
    }, []);
    const Animated = () => {
      //const dispatch = useDispatch();
      const scale = window.innerWidth / 1536;
      const [isGameVisible] = useState(false);
      const [isClose, setClose] = useState(false);
      const mask = useRef();

      const background = useCallback((g) => {
        g.clear();
        g.beginFill(0xffffff, 0.7);
        g.drawRect(0, 0, window.innerWidth, window.innerHeight);
        g.endFill();
      }, []);

      useEffect(() => {
        return () => {
          //console.log("UNMONT");
          //setClose(false);
        };
      }, []);

      const handleClose = (stay, gameId) => {
        setClose(true);
        document.querySelector(".gameHTMLContainer").style.opacity = 0;
        setTimeout(() => {
          close(stay, gameId);
          document.querySelector(".gameHTMLContainer").style.opacity = 1;
        }, 500);
      };

      const games = [
        {
          game: null,
          image: img1,
          dialogButton: "Buscar",
        },
      ];

      return (
        <Spring
          from={{
            alpha: isClose ? 1 : 0,
            scale: games[id]
              ? isClose
                ? 1 * scale
                : 0
              : games[lastId]
              ? 1 * scale
              : 0,
            messageVisible: isGameVisible ? 1 : 0,
            gameVisible: isGameVisible ? 0 : 1,
          }}
          to={{
            alpha: games[id] ? (isClose ? 0 : 1) : 0,
            scale: games[id] ? (isClose ? 0 : 1 * scale) : 0,
            messageVisible: isGameVisible ? 0 : 1,
            gameVisible: isGameVisible ? 1 : 0,
          }}
          config={spring}
        >
          {(props) => (
            <>
              <Graphics
                draw={background}
                interactive
                alpha={props.alpha}
                scale={games[id] ? 1 : 0}
              />
              <Container
                interactive
                scale={props.scale}
                alpha={props.alpha}
                pivot={[window.innerWidth / 2, window.innerHeight / 2]}
                position={[window.innerWidth / 2, window.innerHeight / 2]}
                mask={mask.current}
              >
                <Container>
                  <Sprite
                    image={gamesBg}
                    interactive
                    alpha={props.alpha}
                    scale={games[id] ? 0.7 : 0}
                    anchor={0.5}
                    position={[window.innerWidth / 2, window.innerHeight / 2]}
                  />
                  <Sprite
                    ref={mask}
                    image={gamesBg}
                    interactive
                    alpha={props.alpha}
                    scale={games[id] ? 0.7 : 0}
                    anchor={0.5}
                    position={[window.innerWidth / 2, window.innerHeight / 2]}
                  />
                  <Container
                    alpha={props.messageVisible}
                    scale={props.messageVisible}
                    pivot={[window.innerWidth / 2, window.innerHeight / 2]}
                    position={[window.innerWidth / 2, window.innerHeight / 2]}
                  >
                    <GameDialog
                      image={getMessage(message)}
                      close={() => {
                        if (currentNode.emotion !== "no-emotion") {
                          dispatch(changePhase(currentNode.emotion));
                        }

                        handleClose(true);
                        close(true);
                      }}
                      button={getButtonLabel(message)}
                      buttonPosition={games[id] && games[id].buttonPosition}
                    />
                  </Container>
                </Container>
              </Container>
            </>
          )}
        </Spring>
      );
    };

    return <Animated />;
  },
  (a, b) => a.id === b.id
);

export default Index;
