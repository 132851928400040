import { Container, Sprite } from "@inlet/react-pixi";
import { useEffect, useState } from "react";

import photo from "../../../assets/games/docsRetiroAnticipado/photo2.png";
import ine from "../../../assets/games/docsRetiroAnticipado/ine.png";
import tarjeta from "../../../assets/games/docsRetiroAnticipado/tarjeta.png";
import money from "../../../assets/games/docsRetiroAnticipado/money.png";
import curp from "../../../assets/games/grabGame/01_curp.png";
import nss from "../../../assets/games/grabGame/02_nss.png";

const GRID_SIZE = 3;
const CELL_SIZE = 100;
const TOTAL_CELLS = GRID_SIZE * GRID_SIZE;
const CELL_PADDING = 10;

const getAspectRatio = (id) => {
  if (id === 1 || id === 2 || id === 3) {
    return 2;
  }
  return 1;
};

const getPicture = (id) => {
  switch (id) {
    case 0:
      return photo;
    case 1:
      return ine;
    case 2:
      return money;
    case 3:
      return tarjeta;
    default:
      return Math.random() > 0.5 ? curp : nss;
  }
};

const calculateDimensions = (index) => {
  const ratio = getAspectRatio(index);
  let width = CELL_SIZE - CELL_PADDING * 2;
  let height = CELL_SIZE - CELL_PADDING * 2;

  if (ratio > 1) {
    height = 40;
  } else {
    width = 40;
  }
  return {
    ratio,
    x: (CELL_SIZE - width) / 2,
    y: (CELL_SIZE - height) / 2,
  };
};

const items = Array.from({ length: TOTAL_CELLS }, (_, index) => {
  const picture = getPicture(index);
  const dimensions = calculateDimensions(index);
  return {
    id: index,
    picture,
    ...dimensions,
    gridX: (index % GRID_SIZE) * CELL_SIZE,
    gridY: Math.floor(index / GRID_SIZE) * CELL_SIZE,
    randPos: Math.random(),
  };
})
  .sort((a, b) => a.randPos - b.randPos)
  .map((item, index) => ({ ...item, randPos: index }))
  .map((item) => ({
    ...item,
    x: (item.randPos % GRID_SIZE) * CELL_SIZE,
    y: Math.floor(item.randPos / GRID_SIZE) * CELL_SIZE,
  }));

const Grid = ({ cardPositions, onClick = () => null }) => {
  const [positions, setPositions] = useState(
    items.sort((a, b) => a.randPos - b.randPos)
  );

  useEffect(() => {
    shuffleGrid();
  }, []);

  const shuffleGrid = () => {
    setPositions((prevPositions) =>
      [...prevPositions]
        .sort((a, b) => a.randPos - b.randPos)
        .map((item, index) => ({ ...item, randPos: index }))
        .map((item) => ({
          ...item,
          x: (item.randPos % GRID_SIZE) * CELL_SIZE,
          y: Math.floor(item.randPos / GRID_SIZE) * CELL_SIZE,
        }))
    );
  };

  return (
    <Container
      position={{
        x: -window.innerWidth / 4 + 50,
        y: -window.innerHeight / 4 + 50,
      }}
      width={GRID_SIZE * CELL_SIZE}
      height={GRID_SIZE * CELL_SIZE}
    >
      <Container>
        {positions.map((item) => (
          <Sprite
            cursor="pointer"
            click={() => onClick(item.id)}
            interactive={
              cardPositions.current.length > 0 &&
              cardPositions.current[item.id].alpha === 1
            }
            key={item.id}
            image={item.picture}
            alpha={
              cardPositions.current.length > 0 &&
              cardPositions.current[item.id].alpha === 1
                ? 1
                : 0
            }
            x={item.ratio > 1 ? item.x - CELL_SIZE / 4 : item.x}
            y={item.ratio > 1 ? item.y + CELL_SIZE / 8 : item.y}
            width={item.ratio > 1 ? CELL_SIZE - 10 : CELL_SIZE / 2}
            height={item.ratio <= 1 ? CELL_SIZE - 20 : CELL_SIZE / 1.5}
          />
        ))}
      </Container>
    </Container>
  );
};

export default Grid;
