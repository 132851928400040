import React, { useEffect, useReducer, useState } from "react";
import { Container, Text } from "@inlet/react-pixi/animated";
import { TextStyle } from "pixi.js";
import Selector from "./components/Selector";

import successMessage from "../../assets/games/selectAccount/juego_paso39.png";

import errorMessage from "../../assets/games/selectAccount/juego_paso38.png";
import GameDialog from "../../baseComponents/GameContainer/components/GameDialog";
import { useDispatch } from "react-redux";
import { addTime } from "../../redux/features/withdrawals/time";
import { changePhase } from "../../redux/features/fullWithdrawal/frustometro";

const getRandomNumber = () => {
  const getRandomDigit = () => Math.floor(Math.random() * 9) + 1;
  return Array(6).fill(0).map(getRandomDigit).join("");
};

const initialState = {
  1: {
    start: true,
  },
  2: {
    start: false,
  },
  3: {
    start: false,
  },
  4: {
    start: false,
  },
  5: {
    start: false,
  },
  6: {
    start: false,
  },
};

function reducer(state = initialState, action) {
  if (action.type === "next") {
    return {
      ...state,
      [action.payload]: {
        start: true,
      },
    };
  }

  if (action.type === "reload") {
    return {
      1: {
        start: true,
      },
      2: {
        start: false,
      },
      3: {
        start: false,
      },
      4: {
        start: false,
      },
      5: {
        start: false,
      },
      6: {
        start: false,
      },
    };
  }

  return state;
}

export default function SelectAccount({ visible, close, reset }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fail, setFail] = useState(false);
  const [success, setSuccess] = useState(false);
  const [number, setNumber] = useState(getRandomNumber());
  const dispatcher = useDispatch();

  const style = new TextStyle({
    fontFamily: "Poppins-Medium",
    align: "center",
    fontSize: 50,
    fontWeight: "bold",
    fill: "#127ee0",
    wordWrap: true,
    wordWrapWidth: 350,
  });

  useEffect(() => {
    if (fail) {
      dispatcher(changePhase("frustrated"));
    }
  }, [fail]);

  if (fail) {
    return (
      <GameDialog
        image={errorMessage}
        button="Continuar"
        close={() => {
          dispatch(changePhase("angry"));
          setNumber(getRandomNumber());
          dispatch({ type: "reload" });
          dispatcher(addTime(24));
          setFail(false);
          setSuccess(false);
        }}
        scale={1}
        alpha={1}
      />
    );
  }

  if (success) {
    return (
      <GameDialog
        image={successMessage}
        button="Continuar"
        close={() => {
          dispatch(changePhase("happy"));
          close();
        }}
        scale={1}
        alpha={1}
      />
    );
  }

  return (
    <Container
      position={{
        x: window.innerWidth / 2 - 450,
        y: window.innerHeight / 2 - 150,
      }}
    >
      <Container position={{ x: window.innerWidth / 4 - 10, y: -80 }}>
        <Text
          style={style}
          text={number.split("").join(" ")}
          anchor={0.5}
          x={0}
          y={180}
          position={{ x: 0, y: 0 }}
        />
      </Container>

      <Container position={{ x: window.innerWidth / 8, y: 80 }}>
        <Container
          position={{
            x: -150,
            y: 0,
          }}
        >
          <Selector
            start={state[1].start}
            number={number[0]}
            reset={reset}
            onEndGame={(isValid) => {
              if (isValid) {
                dispatch({ type: "next", payload: 2 });
              } else {
                setFail(true);
              }
            }}
          />
        </Container>
        <Container
          position={{
            x: 0,
            y: 0,
          }}
        >
          <Selector
            start={state[2].start}
            number={number[1]}
            reset={reset}
            onEndGame={(isValid) => {
              if (isValid) {
                dispatch({ type: "next", payload: 3 });
              } else {
                setFail(true);
              }
            }}
          />
        </Container>

        <Container
          position={{
            x: 150,
            y: 0,
          }}
        >
          <Selector
            start={state[3].start}
            number={number[2]}
            reset={reset}
            onEndGame={(isValid) => {
              if (isValid) {
                dispatch({ type: "next", payload: 4 });
              } else {
                setFail(true);
              }
            }}
          />
        </Container>
        <Container
          position={{
            x: 300,
            y: 0,
          }}
        >
          <Selector
            start={state[4].start}
            number={number[3]}
            reset={reset}
            onEndGame={(isValid) => {
              if (isValid) {
                setSuccess(true);
              } else {
                setFail(true);
              }
            }}
          />
        </Container>

        <Container
          position={{
            x: 450,
            y: 0,
          }}
        >
          <Selector
            start={state[5].start}
            number={number[4]}
            reset={reset}
            onEndGame={(isValid) => {
              if (isValid) {
                setSuccess(true);
              } else {
                setFail(true);
              }
            }}
          />
        </Container>

        <Container
          position={{
            x: 600,
            y: 0,
          }}
        >
          <Selector
            start={state[6].start}
            number={number[5]}
            reset={reset}
            onEndGame={(isValid) => {
              if (isValid) {
                setSuccess(true);
              } else {
                setFail(true);
              }
            }}
          />
        </Container>
      </Container>
    </Container>
  );
}
